import Vue from 'vue'
import VueRouter from 'vue-router'
import TopLp from '@/views/TopLp'
import Tc20a from '@/views/Tc20a'
import Tc20b from '@/views/Tc20b'
import Tc20c from '@/views/Tc20c'
import Tc20d from '@/views/Tc20d'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'TopLp',
    component: TopLp
  },
  {
    path: '/tc20a',
    name: 'Tc20a',
    component: Tc20a
  },
  {
    path: '/tc20b',
    name: 'Tc20b',
    component: Tc20b
  },
  {
    path: '/tc20c',
    name: 'Tc20c',
    component: Tc20c
  },
  {
    path: '/tc20d',
    name: 'Tc20d',
    component: Tc20d
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
