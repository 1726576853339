<template>
  <div>
    <TopLayout>
      <template #header>
        <TopHeader />
      </template>
      <template #contents>
        <FirstView />
        <Tc1 />
        <Tc2 />
        <Tc4 />
        <Tc3 />
        <Tc6>
          <template #bt_purchase>
            <PurchaseButton />
          </template>
        </Tc6>
        <!-- <Tc20>
          <template #bt_purchase>
            <PurchaseButton />
          </template>
        </Tc20> -->
        <Tc7Old>
          <template #product>
            <ProductInformation />
          </template>
          <template #bt_purchase>
            <PurchaseButton />
          </template>
          <template #download>
            <DownloadEdition />
          </template>
        </Tc7Old>
        <Tc14 />
        <Tc7 />
        <Tc21>
          <template #bt_purchase>
            <PurchaseButton />
          </template>
        </Tc21>
        <Best10 />
        <Tc7After>
          <!-- <template #product>
            <ProductInformation />
          </template>
          <template #bt_purchase>
            <PurchaseButton />
          </template>
          <template #download>
            <DownloadEdition />
          </template> -->
          <template #table>
            <ProductComparisonTable />
          </template>
        </Tc7After>
        <Gap>
          <template #bt_purchase>
            <PurchaseButton />
          </template>
        </Gap>
        <Tc9>
          <template #bt_purchase>
            <PurchaseButton />
          </template>
        </Tc9>
        <Tc10 />
        <Tc11 />
        <Tc12 />
        <Tc5 />
        <Tc8 />
        <Tc13>
          <template #product>
            <ProductInformation />
          </template>
          <template #bt_purchase>
            <PurchaseButton />
          </template>
        </Tc13>
        <Tc15 />
        <Tc16 />
      </template>
      <template #footer>
        <TopFooter />
      </template>
    </TopLayout>
  </div>
</template>

<script>
import TopLayout from '@/components/TopLayout.vue'
import TopHeader from '@/components/TopHeader.vue'
import TopFooter from '@/components/TopFooter.vue'
import PurchaseButton from '@/components/PurchaseButton.vue'
import FirstView from '@/components/FirstView.vue'
import ProductInformation from '@/components/ProductInformation.vue'
import DownloadEdition from '@/components/DownloadEdition.vue'
import ProductComparisonTable from '@/components/ProductComparisonTable.vue'
import Tc1 from '@/components/Tc1.vue'
import Tc2 from '@/components/Tc2.vue'
import Tc3 from '@/components/Tc3.vue'
import Tc4 from '@/components/Tc4.vue'
import Tc5 from '@/components/Tc5.vue'
import Tc6 from '@/components/Tc6.vue'
import Tc7 from '@/components/Tc7.vue'
import Tc7Old from '@/components/Tc7Old.vue'
import Tc7After from '@/components/Tc7After.vue'
import Tc8 from '@/components/Tc8.vue'
import Gap from '@/components/Gap.vue'
import Tc9 from '@/components/Tc9.vue'
import Tc10 from '@/components/Tc10.vue'
import Tc11 from '@/components/Tc11.vue'
import Tc12 from '@/components/Tc12.vue'
import Tc13 from '@/components/Tc13.vue'
import Tc14 from '@/components/Tc14.vue'
import Tc15 from '@/components/Tc15.vue'
import Tc16 from '@/components/Tc16.vue'
// import Tc20 from '@/components/Tc20.vue'
import Tc21 from '@/components/Tc21.vue'
import Best10 from '@/components/Best10.vue'

export default {
  name: 'TopLp',
  components: {
    TopLayout,
    TopHeader,
    TopFooter,
    PurchaseButton,
    FirstView,
    ProductInformation,
    DownloadEdition,
    ProductComparisonTable,
    Tc1,
    Tc2,
    Tc3,
    Tc4,
    Tc5,
    Tc6,
    Tc7,
    Tc7Old,
    Tc7After,
    Tc8,
    Gap,
    Tc9,
    Tc10,
    Tc11,
    Tc12,
    Tc13,
    Tc14,
    Tc15,
    Tc16,
    // Tc20,
    Tc21,
    Best10,
  },
  head: {
    script: [
      {
        type: 'text/javascript',
        inner: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-5HP4P8JK');
        `,
      }
    ],
  },
}
</script>
