import Vue from 'vue'
import App from '@/App'
import VueLazyload from 'vue-lazyload'
import router from './router'
import store from '../store'
import VueHead from 'vue-head'

Vue.use(VueHead)

Vue.config.productionTip = false

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'https://dummyimage.com/130x120/ccc/999.png&text=Not+Found',
  loading: 'https://dummyimage.com/130x120/dcdcdc/999.png&text=Now loading',
  attempt: 1
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
