<template>
  <div class="container03 inner01">
    <h2><span class="marker">新価格でさらにお買い求めやすくなりました！</span></h2>
    <p>
      購入したライセンス（PDF）をホーム画面やデスクトップに置いておけば、アプリのように手軽に学習ができます（WiFi環境下）。テキストも動画・音声ソフトも専用サイトから自在にストリーミングorダウンロード。教材内容は通常版とまったく同じ。スマホ、タブレット、PC扱いに慣れた人に大人気の廉価版「JJ」です。
    </p>
    <figure class="f2"><img v-lazy="'/top2020/img/LP-DL-image-yellow04.jpg'" alt=""></figure>
    <h3 class="jj">JJ ENGLISHエクササイズ／ダウンロード版</h3>
    <div class="price flexb">
      <p class="flex">新価格</p>
      <div class="txt">
        <p class="p1">15,400円</p>
        <p class="p2">（本体価格：14,000円）</p>
      </div>
      <p class="p5">※お支払いはクレジットカードのみ<br>となります（分割・リボ払い可）</p>
    </div>
    <p class="notice center">
      <span class="marker2">※テキスト／ソフト／特典など、内容はすべて通常版と同じです。</span>
    </p>
    <h3>［通常版との違い］</h3>
    <ul>
      <li>【１】<span class="blue">計５冊の製本テキスト → 付属しません。</span>すべてPDFファイルとなり、専用サイトからのダウンロードでのご利用になります（ダウンロード後、プリントアウト可）。</li>
      <li>【２】<span class="blue">４枚のDVD−ROM → 付属しません。</span>すべてのソフトは専用サイトからのダウンロードかストリーミングでのご利用になります。</li>
      <li>【３】商品の性格上、58日間返金保証の適用外となります。</li>
    </ul>
    <h3>［アップグレードについて］</h3>
    <p>DL版ご購入者に限り、紙のテキスト／DVD-ROMが付属する通常版を税込17,600円（本体価格：16,000円）でご購入できます。※ご注文は電話かメールとなります。</p>
    <p>
      <a class="btn-shop" href="https://jjenglish.shop-pro.jp/?pid=172213217" target="_blank"><img v-lazy="'/top2020/img/DL-button-small.webp'" /></a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'DownloadEdition',
}
</script>

<style scoped lang="scss">
.container03.inner02 {
  width: 70%;
  padding: 60px;
  background-image: url("/top2020/img/tc13_bg2.webp");
  margin-bottom: 40px;
  p {
    text-align: center;
  }
  a {
    display: block;
    width: 250px;
    height: 250px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .title {
    font-size: 38px;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .image {
    height: 250px;
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: center;
    margin-top: 30px;
    img {
      height: 250px;
      width: 376px;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
  .link {
    width: auto;
    text-align: left;
    font-size: 16px;
    margin-top: 10px;
  }
}
.container03.inner01 {
  width: 70%;
  padding: 60px;
  background-image: url("/top2020/img/tc13_bg2.webp");
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 4px solid #fe3340;
  margin-bottom: 40px;
  margin-top: 60px;
  h2 {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    color: #fe3340;
    margin-bottom: 40px;
  }
  h3 {
    text-align: center;
    font-size: 20px;
    line-height: 1.5em;
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 20px;
    &.jj {
      font-size: 36px;
      color: #fe3340;
    }
  }
  p, li {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    .blue {
      color: #0a74d7
    }
    &.center {
      text-align: center;
      margin-top: 1em;
    }
    &.notice {
      font-size: 26px;
      line-height: 1.3em;
    }
  }
  .btn-shop {
    display: block;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    img {
      width: 100%;
    }
  }
}
.price {
  width: 320px;
  margin: 0px auto 30px auto;
  align-items: center;
  .flex {
    width: 90px;
    height: 90px;
    background-color: #fe3340;
    color: #fff;
    font-size: 22px;
    line-height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  .txt {
    position: relative;
    p.p1 {
      font-size: 45px;
      line-height: 32px;
      color: #fe3340;
      margin-bottom: 10px;
    }
    p.p4 {
      width: 366px;
      margin-right: auto;
      margin-left: auto;
      font-size: 58px;
      line-height: 32px;
      color: #fe3340;
      margin-bottom: 10px;
      .sub {
        font-size: 40px;
      }
    }
    p.p2 {
      font-size: 18px;
      line-height: 32px;
      color: #fe3340;
    }
    p.p3 {
      font-size: 18px;
      line-height: 32px;
      text-align: center;
    }
    p.bar1,
    p.bar2 {
      position: absolute;
      bottom: -6px;
      left: -12px;
      width: 160px;
      height: 4px;
      background-color: #fe3340;
    }
    p.bar1 {
      transform: rotate(20deg);
    }
    p.bar2 {
      transform: rotate(-20deg);
    }
  }
  p.p5 {
    font-size: 12px;
    line-height: 24px;
    margin-left: 120px;
  }
}
@media only screen and (max-width: 768px) {
  .container03.inner02 {
    width: calc(100% - 20px);
    .image {
      display: block;
      height: auto;
      width: 250px;
      margin-left: auto;
      margin-right: auto;
      img {
        margin-bottom: 20px;
        width: 250px;
        height: auto;
      }
    }
    p {
      font-size: 16px;
    }
    p.title {
      font-size: 28px;
    }
    p.link {
      font-size: 14px;
      text-align: center;
    }
  }
  .container03.inner01 {
    width: 90%;
    padding: 20px;
    h2 {
      font-size: 23px;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 18px;
      &.jj {
        font-size: 23px;
      }
    }
    p, li {
      font-size: 14px;
      &.notice {
        font-size: 14px;
      }
    }
  }
  .price {
    width: 240px;
    margin: 0px auto 30px auto;
    align-items: center;
    .flex {
      width: 60px;
      height: 60px;
      font-size: 16px;
      line-height: 20px;
      margin-right: 5px;
    }
    .txt {
      margin-bottom: 10px;
      p.p1 {
        font-size: 31px;
        line-height: 32px;
        margin-bottom: 0;
      }
      p.p2 {
        font-size: 15px;
        line-height: 20px;
      }
      p.p4 {
        font-size: 40px;
        line-height: 32px;
        margin-bottom: 0;
        width: 270px;
        margin-right: auto;
        margin-left: auto;
        .sub {
          font-size: 30px;
        }
      }
      p.bar1,
      p.bar2 {
        position: absolute;
        bottom: -8px;
        left: -8px;
        width: 120px;
        height: 4px;
        background-color: #fe3340;
      }
    }
    p.p5 {
      margin-left: 0;
    }
  }
}
</style>
